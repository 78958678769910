import { MutateOptions, useMutation } from '@tanstack/react-query';

import coreApi from 'resources/coreBaseApi';

const replayImport = async (payload: any) => {
  return await coreApi.post('/etl/v2/import/product/push?alk=1', payload);
};

export const useReplayImport = (payload: any, options?: MutateOptions) => {
  return useMutation({
    mutationFn: () => replayImport(JSON.parse(payload)),
    retry: 0,
    ...options,
  });
};
