import { MutateOptions, useMutation } from '@tanstack/react-query';

import coreApi from 'resources/coreBaseApi';

import { IImportStatus } from '../types';

const updateImportStatus = async (id: number, status: IImportStatus) => {
  const payload = { status };
  return await coreApi.post(`/etl/v2/import/product/close/${id}`, payload);
};

export const useUpdateImportStatus = (
  id: number,
  status: IImportStatus,
  options?: MutateOptions,
) => {
  return useMutation({
    mutationFn: () => updateImportStatus(id, status),
    retry: 0,
    ...options,
  });
};
