import {
  ImportFilters,
  ImportProvider,
  ImportTable,
} from 'dashboards/import/components';
import { Banner, BannerType } from 'dashboards/shared/components';

import './ImportListPage.scss';

export const ImportListPage = () => {
  return (
    <ImportProvider>
      <div className="UIImportListPage">
        <Banner type={BannerType.WARNING}>
          This is the latest version of the Import Dashboard. If you encounter
          any functionality that was available in the previous version but is
          not accessible now, you can switch to the old version{' '}
          <a target="_blank" href="/io/import">
            here
          </a>
          . Additionally, please send a Slack message to the SXM team detailing
          what you were unable to do.
        </Banner>
        <div className="UIImportListPage__Container">
          <ImportFilters />
          <ImportTable />
        </div>
      </div>
    </ImportProvider>
  );
};
