import './Button.scss';

interface ButtonProps {
  onClick: () => void;
  label: string;
  disabled?: boolean;
  fullWidth?: boolean;
}

export const Button = ({
  onClick,
  label,
  disabled,
  fullWidth,
}: ButtonProps) => {
  return (
    <button
      className="UIButton"
      onClick={onClick}
      style={{ width: fullWidth ? '100%' : 'fit-content' }}
      disabled={disabled}
    >
      {label}
    </button>
  );
};
