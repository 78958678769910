import { Modal } from '@mui/material';
import { useUpdateImportSupportStatus } from 'dashboards/import/hooks';
import { IImportReport, IImportSupportStatus } from 'dashboards/import/types';
import { Button, FormLabel, SingleSelect } from 'dashboards/shared/components';
import { useEffect, useState } from 'react';

import './ImportSupportStatusModal.scss';

interface ImportSupportStatusModalProps {
  open: boolean;
  onClose: () => void;
  importReport?: IImportReport;
  refetch: () => void;
}

export const ImportSupportStatusModal = ({
  open,
  onClose,
  importReport,
  refetch,
}: ImportSupportStatusModalProps) => {
  const [supportStatus, setSupportStatus] = useState<string | undefined>(
    importReport?.support?.status,
  );

  const isValidForm = () => {
    return importReport?.support?.status !== supportStatus;
  };

  const { mutate: updateSupportStatus } = useUpdateImportSupportStatus(
    importReport?.id as number,
    supportStatus as IImportSupportStatus,
    {
      onSuccess: () => {
        onClose();
        refetch();
      },
    },
  );

  const supportStatuses = Object.keys(IImportSupportStatus).map((key) => ({
    id: key,
    label: key,
  }));

  useEffect(() => {
    setSupportStatus(importReport?.support?.status);
  }, [importReport]);

  return (
    <Modal open={open} onClose={onClose}>
      <div className="UIImportSupportStatusModal">
        <div className="UIImportSupportStatusModal__Section">
          <FormLabel label="Support Status" />
          <SingleSelect
            options={supportStatuses}
            onChange={setSupportStatus}
            value={supportStatus}
          />
        </div>
        <div className="UIImportSupportStatusModal__Section">
          <Button
            onClick={updateSupportStatus}
            label={'Update'}
            fullWidth
            disabled={!isValidForm()}
          />
        </div>
      </div>
    </Modal>
  );
};
