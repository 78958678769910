import coreApi from 'resources/coreBaseApi';
import { saveAs } from 'utils';

export const downloadImportErrorReport = async (importId?: number) => {
  const payload = { pif_id: importId };
  const response = await coreApi.getBlob(
    '/etl/v1/products/import/report/generate',
    '',
    payload,
    true,
  );
  saveAs(response.data, 'Errors.xlsx');
};

export const downloadImportReport = async (
  fileName: string,
  importId: number,
) => {
  const response = await coreApi.getBlob(
    `/etl/v1/products/import/${importId}/file?alk=1`,
    '',
  );
  saveAs(response.data, fileName);
};
