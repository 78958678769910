import { useQuery } from '@tanstack/react-query';
import qs from 'querystringify';

import coreApi from 'resources/coreBaseApi';

import { IImportReport } from '../types';

interface IAPIImportReport {
  data: {
    data: IImportReport;
  };
}

interface IAPIImportReportParams {
  filter_error_only: boolean;
  filter_references_in?: string;
  offset: number;
  limit: number;
}

const fetchImportReport = async (
  id: number,
  params: IAPIImportReportParams,
): Promise<IAPIImportReport> => {
  const realParams = Object.fromEntries(
    Object.entries(params).flatMap(([key, value]) =>
      value !== undefined ? [[key, value]] : [],
    ),
  );
  return await coreApi.get(
    `/etl/v1/products/import/report/${id}` + qs.stringify(realParams, true),
  );
};

export const useGetImportReport = (
  id: number,
  params: IAPIImportReportParams,
) => {
  return useQuery<IAPIImportReport, Error>({
    queryKey: ['importReport', id, params],
    queryFn: () => fetchImportReport(id, params),
    retry: 0,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
};
