import { IColor } from 'dashboards/shared/types';

import './Chip.scss';

interface ChipProps {
  label: string;
  color: IColor;
  onClick?: () => void;
}

export const Chip = ({ label, color, onClick }: ChipProps) => {
  return (
    <span
      className="UIChip"
      style={{
        backgroundColor: color,
        cursor: onClick ? 'pointer' : 'default',
      }}
      onClick={onClick}
    >
      {label}
    </span>
  );
};
