import { useFilter } from 'dashboards/import/components';
import { useGetImportFilters } from 'dashboards/import/hooks';
import { IImportStatus, IImportSupportStatus } from 'dashboards/import/types';
import {
  Button,
  DateInput,
  FormLabel,
  MultiSelect,
  SingleSelect,
  Switch,
  TextInput,
} from 'dashboards/shared/components';

import './ImportFilters.scss';

export const ImportFilters = () => {
  const { setFilter, filters, clearFilters } = useFilter();

  const {
    data: { data: { data: { which = [], organization = [] } = {} } = {} } = {},
  } = useGetImportFilters();

  const statuses = Object.keys(IImportStatus).map((key) => ({
    id: key,
    label: key,
  }));

  const supportStatuses = Object.keys(IImportSupportStatus).map((key) => ({
    id: key,
    label: key,
  }));

  const formats = which.map((format) => ({
    id: format,
    label: format,
  }));

  const organizations = organization.map(({ id, nameLegal }) => ({
    id,
    label: `${nameLegal} (${id})`,
  }));

  const setReferences = (value: string | undefined) => {
    setFilter('filter_references_in', value);
  };

  const setFormats = (options: string[] | undefined) => {
    setFilter('filter_spiders', options);
  };

  const setOrganizations = (options: string[] | undefined) => {
    setFilter('filter_organization_ids', options);
  };

  const setStatus = (option: string | undefined) => {
    setFilter('filter_status', option);
  };

  const setSupportStatus = (option: string | undefined) => {
    setFilter('filter_support_status_in', option);
  };

  const setDate = (value: string | undefined) => {
    setFilter('filter_date', value);
  };

  const setDateFrom = (value: string | undefined) => {
    setFilter('filter_date_from', value);
  };

  const setDateTo = (value: string | undefined) => {
    setFilter('filter_date_to', value);
  };

  const setHideUnknownGLN = (state: boolean) => {
    setFilter('filter_unknown_gln', state);
  };

  const setHideAlkMonitoring = (state: boolean) => {
    setFilter('filter_user_ids_out', state);
  };

  const setHideEmpty = (state: boolean) => {
    setFilter('filter_empty_out', state);
  };

  return (
    <div className="UIImportFilters">
      <div className="UIImportFilters__section">
        <Button onClick={clearFilters} label={'Clear All Filters'} fullWidth />
      </div>
      <div className="UIImportFilters__section">
        <FormLabel label={'GTIN'} />
        <TextInput
          onChange={setReferences}
          value={filters.filter_references_in}
        />
      </div>
      <div className="UIImportFilters__section">
        <FormLabel label={'Formats'} />
        <MultiSelect
          options={formats}
          onChange={setFormats}
          value={filters.filter_spiders}
        />
      </div>
      <div className="UIImportFilters__section">
        <FormLabel label={'Organizations'} />
        <MultiSelect
          options={organizations}
          onChange={setOrganizations}
          value={filters.filter_organization_ids as unknown as string[]}
        />
      </div>
      <div className="UIImportFilters__section">
        <FormLabel label={'Status'} />
        <SingleSelect
          options={statuses}
          onChange={setStatus}
          value={filters.filter_status}
        />
      </div>
      <div className="UIImportFilters__section">
        <FormLabel label={'Support Status'} />
        <SingleSelect
          options={supportStatuses}
          onChange={setSupportStatus}
          value={filters.filter_support_status_in}
        />
      </div>
      <div className="UIImportFilters__section">
        <FormLabel label={'Exact Date'} />
        <DateInput onChange={setDate} value={filters.filter_date} />
      </div>
      <div className="UIImportFilters__section">
        <FormLabel label={'Date From'} />
        <DateInput onChange={setDateFrom} value={filters.filter_date_from} />
      </div>
      <div className="UIImportFilters__section">
        <FormLabel label={'Date From'} />
        <DateInput onChange={setDateTo} value={filters.filter_date_to} />
      </div>
      <div className="UIImportFilters__section">
        <FormLabel label={'Other'} />
        <Switch
          label={'Hide unknown GLN'}
          onChange={setHideUnknownGLN}
          value={filters.filter_unknown_gln}
        />
        <Switch
          label={'Hide alk_monitoring'}
          onChange={setHideAlkMonitoring}
          value={filters.filter_user_ids_out}
        />
        <Switch
          label={'Hide empty'}
          onChange={setHideEmpty}
          value={filters.filter_empty_out}
        />
      </div>
    </div>
  );
};
