import classNames from 'classnames';

import './Switch.scss';

type SwitchProps = {
  label?: string;
  value: boolean;
  onChange?: (state: boolean) => void;
};

export const Switch = ({
  label,
  value = false,
  onChange = () => {},
}: SwitchProps) => {
  const handleToggle = () => {
    onChange(!value);
  };

  return (
    <div className="UISwitch">
      <div
        className={classNames('UISwitch__Toggle', {
          UISwitch__Toggle__On: value,
        })}
        onClick={handleToggle}
      >
        <div
          className={classNames('UISwitch__Thumb', {
            UISwitch__Thumb__On: value,
          })}
        />
      </div>
      {label && <span className="UISwitch__Label">{label}</span>}
    </div>
  );
};
