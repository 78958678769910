export interface IImportFilters {
  alk: number;
  filter_references_in?: string;
  filter_organization_ids?: number[];
  filter_user_ids_out: boolean;
  filter_unknown_gln: boolean;
  filter_empty_out: boolean;
  filter_status?: string;
  filter_spiders?: string[];
  filter_support_status_in?: string;
  filter_date?: string;
  filter_date_from?: string;
  filter_date_to?: string;
  offset: number;
  limit: number;
}

export enum IImportStatus {
  SUCCESS = 'SUCCESS',
  IN_PROGRESS = 'IN_PROGRESS',
  CONTAINS_ERRORS = 'CONTAINS_ERRORS',
  ERROR = 'ERROR',
  TIMEOUT = 'TIMEOUT',
  EMPTY = 'EMPTY',
}

export enum IImportSupportStatus {
  TO_DO = 'TO_DO',
  IN_PROGRESS = 'IN_PROGRESS',
  DONE = 'DONE',
}

export interface IImportReportProductImport {
  id: string;
  gtin: string;
  targetOrganization: {
    id: string;
    nameLegal: string;
  };
  steps: {
    name: string;
    status: string;
    message: string;
    time: string;
    validationReport?: Object;
  }[];
}

export interface IImportReportStep {
  name: string;
  status: string;
  message: string;
  time: string;
  validation_report?: Object;
}

export interface IImportReport {
  id: number;
  counts: {
    total: number;
    success: number;
  };
  createdAt: string;
  duration: number;
  spider: string;
  status: IImportStatus;
  support: {
    status: IImportSupportStatus;
  };
  sourceOrganization: {
    id: number;
    nameLegal: string;
    type: number;
  };
  targetOrganizations: {
    id: string;
    nameLegal: string;
  }[];
  user: {
    id: string;
    username: string;
  };
  steps: IImportReportStep[];
  productImports: IImportReportProductImport[];
  parameters: {
    fetcher?: {
      location?: string;
    };
    publication_id?: string;
  };
  replayParameters: {
    replay_info?: {
      original_import_id: number;
    };
  };
}
