export enum IImportReportType {
  DATA = 'Data',
  RANGE = 'Range',
  GDSN = 'GDSN',
}

export const MERGE_MODES = [
  {
    value: 'deep_patch',
    label: 'Merge list',
  },
  {
    value: 'patch',
    label: 'Override list (except labels)',
  },
  {
    value: 'patch_all',
    label: 'Override list',
  },
  {
    value: 'no_patch',
    label: '⚠ Override all ⚠',
  },
];

export const FORMATS = [
  {
    value: 'JSON',
    label: 'Salsify SupplierXM JSON',
  },
  {
    value: 'AlkemicsV2',
    label: 'Salsify SupplierXM XML V2',
  },
  {
    value: 'Alkemics',
    label: 'Salsify SupplierXM XML V1',
  },
  {
    value: 'XLSX',
    label: 'Salsify SupplierXM XLSX',
  },
  {
    value: 'XLSX2',
    label: 'Salsify SupplierXM XLSX v2',
  },
  {
    value: 'FABDIS',
    label: 'FAB-DIS XLSX',
  },
  {
    value: 'GDSN3',
    label: 'GDSN 3.1',
  },
  {
    value: 'Brandbank',
    label: 'BrandBank XML',
  },
  {
    value: 'Casino',
    label: 'Casino MDD XLSX',
  },
  {
    value: 'NESTLE',
    label: 'Nestlé PDH API',
  },
  {
    value: 'CASINOXLSXDISPATCH',
    label: 'Casino mailbox',
  },
  {
    value: 'ART04',
    label: 'Retailer: ART04',
    type: IImportReportType.RANGE,
  },
  {
    value: 'ART04B',
    label: 'Retailer: ART04B',
    type: IImportReportType.RANGE,
  },
  {
    value: 'CASINOXML',
    label: 'Retailer: Casino XML',
    type: IImportReportType.RANGE,
  },
  {
    value: 'ALKEMICSXLSX2',
    label: 'Salsify SupplierXM XLSX2',
    type: IImportReportType.RANGE,
  },
  {
    value: 'BRANDBANKJSONAPI',
    label: 'BrandBank JSON UK',
  },
  {
    value: 'BRANDBANKJSONAPIGLOBAL',
    label: 'BrandBank JSON Global',
  },
].sort((a, b) => a.label.localeCompare(b.label));
