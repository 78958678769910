import { useQuery } from '@tanstack/react-query';
import qs from 'querystringify';

import coreApi from 'resources/coreBaseApi';

interface IAPIOrganizations {
  data: {
    data: {
      id: string;
      nameLegal: string;
    }[];
  };
}

interface IAPIOrganizationsParams {
  type_in?: number;
  status_in?: number;
}

const fetchOrganizations = async (
  params: IAPIOrganizationsParams,
): Promise<IAPIOrganizations> => {
  return await coreApi.get(
    '/auth/v3/organization' + qs.stringify(params, true),
  );
};

export const useGetOrganizations = (params: IAPIOrganizationsParams) => {
  return useQuery<IAPIOrganizations, Error>({
    queryKey: ['organizations', params],
    queryFn: () => fetchOrganizations(params),
    retry: 0,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
};
