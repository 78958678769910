import { List, fromJS, is } from 'immutable';

import { createReducer } from 'utils/reducers';

import * as events from '../constants';

export const initialState = fromJS({
  list: [],
  referencialGroups: [],
  byId: {},
  refreshingCache: false,
  isLoading: false,
  entityIdsToDelete: [],
  linkedAttributes: {},
});

const updateEntityKey = (state, action) => {
  const dirty = !is(
    state.getIn([
      'byId',
      action.referentialId,
      'source',
      action.index,
      action.key,
    ]),
    action.value,
  );
  return state
    .setIn(
      ['byId', action.referentialId, 'edited', action.index, action.key],
      action.value,
    )
    .setIn(['byId', action.referentialId, 'dirty'], dirty);
};

const addEntity = (state, action) => _addEntity(state, action.referentialId);

const deleteEntity = (state, { referentialId, index, isNewlyCreated }) => {
  let newState = state;
  if (!isNewlyCreated) {
    const entity = state.getIn(['byId', referentialId, 'edited', index]);
    newState = newState
      .set(
        'entityIdsToDelete',
        newState.get('entityIdsToDelete').push(entity.get('id')),
      )
      .setIn(['byId', referentialId, 'dirty'], true);
  }
  return newState.deleteIn(['byId', referentialId, 'edited', index]);
};

const receiveReferentialList = (state, action) => {
  const list = action.list
    .map((r) => r.set('checked', false).set('value', r.get('id')))
    .sortBy((r) => r.get('label'));
  return state.set('list', list);
};

const selectReferential = (state, { referential }) => {
  const list = state.get('list').map((r) => {
    const checked = r.get('id') === referential.get('id');
    return r.set('checked', checked);
  });
  return state.set('list', list);
};

const receiveReferentialEntities = (state, action) =>
  state
    .setIn(['byId', action.id, 'source'], action.list)
    .setIn(['byId', action.id, 'edited'], action.list)
    .setIn(['byId', action.id, 'dirty'], false)
    .set('entityIdsToDelete', List());

const receiveReferentialGroups = (state, action) => {
  const list = action.list
    .map((r) => r.set('checked', false).set('value', r.get('name')))
    .sortBy((r) => r.get('label'));
  return state.set('referencialGroups', list);
};

const receiveReferentialLinkedAttributes = (state, action) => {
  return state.setIn(['linkedAttributes', action.id], action.linkedAttributes);
};

const selectReferentialGroups = (state, action) => {
  const selectedGroup = action.referentialGroup;
  const list = state.get('referencialGroups').map((r) => {
    const checked = r.get('value') === selectedGroup.get('value');
    return r.set('checked', checked);
  });
  return state.set('referencialGroups', list);
};

function _addEntity(state, referentialId, entity) {
  const getEmptyReferentialEntity = () =>
    fromJS({
      code: null,
      label: null,
      description: null,
      rank: null,
      data: {},
    });

  let newState = state;

  // add an empty entity to source data as empty
  newState = newState.setIn(
    ['byId', referentialId, 'source'],
    newState
      .getIn(['byId', referentialId, 'source'])
      .push(getEmptyReferentialEntity()),
  );

  // add entity to edited data
  newState = newState.setIn(
    ['byId', referentialId, 'edited'],
    newState
      .getIn(['byId', referentialId, 'edited'])
      .push(entity ? fromJS(entity) : getEmptyReferentialEntity()),
  );

  // set referential as dirty
  newState = newState.setIn(['byId', referentialId, 'dirty'], true);

  return newState;
}

export default createReducer(initialState, {
  [events.RECEIVED_REFERENTIAL_LIST]: receiveReferentialList,
  [events.SELECT_REFERENTIAL]: selectReferential,
  [events.SELECT_REFERENTIAL_GROUP]: selectReferentialGroups,
  [events.RECEIVED_REFERENTIAL_ENTITIES]: receiveReferentialEntities,
  [events.RECEIVED_REFERENTIAL_GROUPS]: receiveReferentialGroups,
  [events.RECEIVED_REFERENTIAL_LINKEDATTRIBUTES]:
    receiveReferentialLinkedAttributes,
  [events.UPDATE_ENTITY_KEY]: updateEntityKey,
  [events.ADD_ENTITY]: addEntity,
  [events.DELETE_ENTITY]: deleteEntity,
  [events.REFRESHING_REFERENTIAL_CACHE]: (state, { refreshing }) =>
    state.set('refreshingCache', refreshing),
  [events.SELECT_REFERENTIAL_LOADING]: (state, { refreshing }) =>
    state.set('isLoading', refreshing),
  [events.REFERENTIAL_LINKEDATTRIBUTES_LOADING]: (state, { refreshing }) =>
    state.set('isLoading', refreshing),
});
