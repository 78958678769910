import { Modal } from '@mui/material';
import {
  FORMATS,
  IImportReportType,
  MERGE_MODES,
} from 'dashboards/import/constants';
import { useCreateImport } from 'dashboards/import/hooks';
import { useGetOrganizations } from 'dashboards/organization/hooks';
import {
  Button,
  FileInput,
  FormLabel,
  SingleSelect,
} from 'dashboards/shared/components';
import { useEffect, useState } from 'react';

import './ImportUploadModal.scss';

interface ImportUploadModalProps {
  open: boolean;
  onClose: () => void;
  refetch: () => void;
}

export const ImportUploadModal = ({
  open,
  onClose,
  refetch,
}: ImportUploadModalProps) => {
  const [type, setType] = useState<string | undefined>(undefined);
  const [file, setFile] = useState<File | null>(null);
  const [organizationId, setOrganizationId] = useState<string | undefined>(
    undefined,
  );
  const [format, setFormat] = useState<string | undefined>(undefined);
  const [merge, setMerge] = useState<string | undefined>(undefined);

  const clearFields = () => {
    setOrganizationId(undefined);
    setFormat(undefined);
    setMerge(undefined);
  };

  const getPayload = () => {
    if (type === IImportReportType.DATA) {
      return {
        synchronous: false,
        from_dashboard: true,
        merge_mode: merge,
        spider: format,
        organization_id: organizationId,
      };
    } else if (type === IImportReportType.RANGE) {
      return {
        mode: 'delta',
        format,
        organization_id: organizationId,
      };
    } else {
      return {};
    }
  };

  const getFormats = () => {
    if (type === IImportReportType.RANGE) {
      return FORMATS.filter(({ type }) => type === IImportReportType.RANGE);
    } else if (type === IImportReportType.DATA) {
      return FORMATS.filter(({ type }) => !type);
    } else {
      return [];
    }
  };

  const isValidForm = () => {
    if (type === IImportReportType.DATA) {
      return !!file && !!organizationId && !!format && !!merge;
    } else if (type === IImportReportType.RANGE) {
      return !!file && !!organizationId && !!format;
    } else if (type === IImportReportType.GDSN) {
      return !!file;
    }
    return false;
  };

  const { data: { data: { data: organizations = [] } = {} } = {} } =
    useGetOrganizations({
      status_in: 1,
      ...(type === IImportReportType.RANGE && { type_in: 2 }),
    });

  const { mutate: createImport } = useCreateImport(
    type as IImportReportType,
    getPayload(),
    file,
    {
      onSuccess: () => {
        clearFields();
        onClose();
        refetch();
      },
    },
  );

  const types = Object.values(IImportReportType).map((value) => ({
    id: value,
    label: value,
  }));

  const organizationsOptions = organizations.map(({ id, nameLegal }) => ({
    id: id,
    label: nameLegal,
  }));

  const formats = getFormats().map(({ value, label }) => ({
    id: value,
    label,
  }));

  const mergeModes = MERGE_MODES.map(({ value, label }) => ({
    id: value,
    label,
  }));

  useEffect(() => {
    clearFields();
  }, [type]);

  return (
    <Modal open={open} onClose={onClose}>
      <div className="UIImportUploadModal">
        <div className="UIImportUploadModal__Section">
          <FormLabel label="Import Type" />
          <SingleSelect options={types} onChange={setType} value={type} />
        </div>
        <div className="UIImportUploadModal__Section">
          <FormLabel label="Import File" />
          <FileInput onChange={setFile} />
        </div>
        <div className="UIImportUploadModal__Section">
          <FormLabel label="File Format" />
          <SingleSelect
            options={formats}
            onChange={setFormat}
            value={format}
            disabled={!type || type === IImportReportType.GDSN}
          />
        </div>
        <div className="UIImportUploadModal__Section">
          <FormLabel label="Organization" />
          <SingleSelect
            options={organizationsOptions}
            onChange={setOrganizationId}
            value={organizationId}
            disabled={!type || type === IImportReportType.GDSN}
          />
        </div>
        <div className="UIImportUploadModal__Section">
          <FormLabel label="Merge Type" />
          <SingleSelect
            options={mergeModes}
            onChange={setMerge}
            value={merge}
            disabled={!type || type !== IImportReportType.DATA}
          />
        </div>
        <div className="UIImportUploadModal__Section">
          <Button
            onClick={createImport}
            label={'Upload'}
            fullWidth
            disabled={!isValidForm()}
          />
        </div>
      </div>
    </Modal>
  );
};
