import { IMPORT_PAGINATION } from 'dashboards/import/constants';
import {
  downloadImportReport,
  useGetImportReports,
} from 'dashboards/import/hooks';
import { IImportReport } from 'dashboards/import/types';
import {
  getImportStatusChipColor,
  getImportSupportStatusChipColor,
} from 'dashboards/import/utils';
import { Button, Chip } from 'dashboards/shared/components';
import { IColor } from 'dashboards/shared/types';
import {
  MRT_ColumnDef,
  MRT_PaginationState,
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import { useState } from 'react';

import {
  ImportDetailsModal,
  ImportReplayModal,
  ImportStatusModal,
  ImportSupportStatusModal,
  ImportUploadModal,
} from '../modals';
import { useFilter } from '../provider';

import './ImportTable.scss';

export const ImportTable = () => {
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [openSupportStatusModal, setOpenSupportStatusModal] = useState(false);
  const [openStatusModal, setOpenStatusModal] = useState(false);
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [openReplayModal, setOpenReplayModal] = useState(false);

  const [selectedImport, setSelectedImport] = useState<
    IImportReport | undefined
  >(undefined);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const { filters } = useFilter();

  const {
    isLoading,
    refetch: refetchImports,
    data: { data: { data: reports = [], totalResults = 0 } = {} } = {},
  } = useGetImportReports({
    ...filters,
    offset: pagination.pageIndex * pagination.pageSize,
    limit: pagination.pageSize,
  });

  const columns: MRT_ColumnDef<IImportReport>[] = [
    {
      header: 'Organization',
      Cell: ({ row }) => {
        const { id, nameLegal } = row.original.sourceOrganization || {};
        const { id: userId, username } = row.original.user || {};
        return (
          <div className="UIImportTable__Columns">
            <span>
              {nameLegal} ({id})
            </span>
            {username && (
              <span className="UIImportTable__Columns__User">
                {username} ({userId})
              </span>
            )}
          </div>
        );
      },
    },
    {
      header: 'Format',
      Cell: ({ row }) => {
        const { spider } = row.original;
        return <Chip color={IColor.INDIGO} label={spider} />;
      },
    },
    {
      header: 'Items',
      Cell: ({ row }) => {
        const {
          counts: { total, success },
          duration,
        } = row.original || {};
        const timePerImport = Math.round(duration / total);
        return (
          <div className="UIImportTable__Columns">
            <span>
              {success} / {total}
            </span>
            <span>({duration}s)</span>
            {total > 0 && (
              <span className="UIImportTable__Columns__Time">
                {timePerImport}s/item
              </span>
            )}
          </div>
        );
      },
    },
    {
      header: 'Status',
      Cell: ({ row }) => {
        const { status } = row.original;
        return (
          <Chip
            color={getImportStatusChipColor(status)}
            label={status}
            onClick={() => {
              setSelectedImport(row.original);
              setOpenStatusModal(true);
            }}
          />
        );
      },
    },
    {
      header: 'Support Status',
      Cell: ({ row }) => {
        const {
          support: { status: supportStatus },
        } = row.original;
        return (
          <Chip
            color={getImportSupportStatusChipColor(supportStatus)}
            label={supportStatus}
            onClick={() => {
              setSelectedImport(row.original);
              setOpenSupportStatusModal(true);
            }}
          />
        );
      },
    },
    {
      accessorKey: 'createdAt',
      header: 'Created At',
      Cell: ({ row }) => {
        const { createdAt } = row.original;
        const date = createdAt.split('T')[0];
        const time = createdAt.split('T')[1];
        return (
          <div className="UIImportTable__Columns">
            <span>{date}</span>
            <span className="UIImportTable__Columns__Time">{time}</span>
          </div>
        );
      },
    },
    {
      header: 'Actions',
      size: 150,
      Cell: ({ row }) => {
        return (
          <div className="UIImportTable__Columns__Actions">
            <i
              className="mdi mdi-eye"
              onClick={() => {
                setSelectedImport(row.original);
                setOpenDetailsModal(true);
              }}
            ></i>
            <i
              className="mdi mdi-download"
              onClick={() => {
                const fileName = row.original.parameters
                  .fetcher!.location!.split('/')
                  .pop();
                downloadImportReport(fileName!, row.original.id);
              }}
            ></i>
            <i
              className="mdi mdi-sync"
              onClick={() => {
                setSelectedImport(row.original);
                setOpenReplayModal(true);
              }}
            ></i>
          </div>
        );
      },
    },
  ];

  const table = useMaterialReactTable({
    columns,
    data: reports,
    state: {
      isLoading,
      pagination,
    },
    muiPaginationProps: {
      rowsPerPageOptions: IMPORT_PAGINATION,
    },
    layoutMode: 'grid',
    rowCount: totalResults,
    manualPagination: true,
    onPaginationChange: setPagination,
    enableKeyboardShortcuts: false,
    enableColumnActions: false,
    enableColumnFilters: false,
    enableSorting: false,
    enableTopToolbar: false,
    enableStickyHeader: true,
    muiTableContainerProps: {
      sx: {
        height: 'calc(100vh - 348px)',
        '::-webkit-scrollbar': { display: 'none' },
      },
    },
    muiTablePaperProps: {
      sx: { boxShadow: 'none', border: '1px solid #E0E0E0' },
    },
    muiTableBodyRowProps: {
      hover: false,
      sx: {
        ':last-child': {
          borderBottom: 'unset',
        },
      },
    },
    muiTableHeadCellProps: {
      sx: {
        color: 'white',
        backgroundColor: '#166a83',
      },
    },
    muiBottomToolbarProps: {
      sx: {
        borderTop: '1px solid #E0E0E0',
        boxShadow: 'none',
      },
    },
    muiTableHeadProps: {
      sx: {
        border: 'unset',
        boxShadow: 'none',
      },
    },
  });

  return (
    <div className="UIImportTable">
      <div className="UIImportTable__Actions">
        <Button label="New Import" onClick={() => setOpenUploadModal(true)} />
      </div>
      <MaterialReactTable table={table} />
      <ImportUploadModal
        open={openUploadModal}
        onClose={() => setOpenUploadModal(false)}
        refetch={refetchImports}
      />
      <ImportSupportStatusModal
        open={openSupportStatusModal}
        onClose={() => setOpenSupportStatusModal(false)}
        refetch={refetchImports}
        importReport={selectedImport}
      />
      <ImportStatusModal
        open={openStatusModal}
        onClose={() => setOpenStatusModal(false)}
        refetch={refetchImports}
        importReport={selectedImport}
      />
      <ImportDetailsModal
        open={openDetailsModal}
        onClose={() => setOpenDetailsModal(false)}
        importReportId={selectedImport?.id}
      />
      <ImportReplayModal
        open={openReplayModal}
        onClose={() => setOpenReplayModal(false)}
        importReport={selectedImport}
        refetch={refetchImports}
      />
    </div>
  );
};
