import { Modal } from '@mui/material';
import {
  downloadImportErrorReport,
  useGetImportReport,
} from 'dashboards/import/hooks';
import {
  IImportReport,
  IImportReportProductImport,
  IImportReportStep,
} from 'dashboards/import/types';
import {
  Button,
  Chip,
  FormLabel,
  Switch,
  TextInput,
} from 'dashboards/shared/components';
import { IColor } from 'dashboards/shared/types';
import {
  MRT_ColumnDef,
  MRT_PaginationState,
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import { useState } from 'react';

import { ORGANIZATION_TYPE_RETAILER } from 'constants/organization';
import * as routes from 'constants/routes';

import { ImportStepModal } from '../step';

import './ImportDetailsModal.scss';

interface ImportDetailsModalProps {
  open: boolean;
  onClose: () => void;
  importReportId?: number;
}

export const ImportDetailsModal = ({
  open,
  onClose,
  importReportId,
}: ImportDetailsModalProps) => {
  const [references, setReferences] = useState<string | undefined>(undefined);
  const [errorOnly, setErrorOnly] = useState<boolean>(false);
  const [openStepModal, setOpenStepModal] = useState(false);
  const [step, setStep] = useState<IImportReportStep>();

  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 5,
  });

  const {
    isLoading,
    data: { data: { data: importReport = {} as IImportReport } = {} } = {},
  } = useGetImportReport(importReportId!, {
    filter_error_only: errorOnly,
    ...(references && { filter_references_in: references }),
    offset: pagination.pageIndex * pagination.pageSize,
    limit: pagination.pageSize,
  });

  const columns: MRT_ColumnDef<IImportReportProductImport>[] = [
    {
      header: 'GTIN',
      accessorKey: 'gtin',
      maxSize: 30,
    },
    {
      header: 'Organization',
      accessorKey: 'targetOrganization.nameLegal',
      maxSize: 100,
    },
    {
      header: 'Steps',
      Cell: ({ row }) => {
        const { steps, id } = row.original;

        const chips = steps.flatMap((step, index) => [
          <Chip
            key={`${id}-${step.name}`}
            label={step.name}
            color={step.status === 'SUCCESS' ? IColor.TEAL : IColor.RED}
            onClick={() => {
              setOpenStepModal(true);
              setStep(step);
            }}
          />,
          index < steps.length - 1 ? <span key={index}>&rarr;</span> : null,
        ]);

        return (
          <div className="UIImportDetailsModal__Table__Steps">{chips}</div>
        );
      },
    },
    {
      header: 'Actions',
      Cell: ({ row }) => {
        return (
          <div className="UIImportDetailsModal__Table__Actions">
            {importReport.sourceOrganization.type !==
              ORGANIZATION_TYPE_RETAILER.id && (
              <i
                className="mdi mdi-sitemap"
                onClick={() =>
                  window.open(
                    `${routes.transaction}?sourceEntities=ProductImport:${row.original.id}`,
                    '_blank',
                  )
                }
              />
            )}
          </div>
        );
      },
      maxSize: 25,
    },
  ];

  const table = useMaterialReactTable({
    columns,
    data: importReport.productImports || [],
    state: {
      density: 'compact',
      isLoading,
      pagination,
    },
    layoutMode: 'grid',
    muiPaginationProps: {
      rowsPerPageOptions: [5],
    },
    rowCount: importReport.counts?.total || 0,
    manualPagination: true,
    onPaginationChange: setPagination,
    enableKeyboardShortcuts: false,
    enableColumnActions: false,
    enableColumnFilters: false,
    enableSorting: false,
    enableTopToolbar: false,
    enableStickyHeader: true,
    muiTableContainerProps: {
      sx: {
        height: 'calc(5 * 45px + 35px)',
        '::-webkit-scrollbar': { display: 'none' },
      },
    },
    muiTablePaperProps: {
      sx: { boxShadow: 'none', border: '1px solid #E0E0E0' },
    },
    muiTableBodyRowProps: {
      hover: false,
      sx: {
        ':last-child': {
          borderBottom: 'unset',
        },
      },
    },
    muiTableHeadCellProps: {
      sx: {
        color: 'white',
        backgroundColor: '#166a83',
      },
    },
    muiBottomToolbarProps: {
      sx: {
        borderTop: '1px solid #E0E0E0',
        boxShadow: 'none',
      },
    },
    muiTableHeadProps: {
      sx: {
        border: 'unset',
        boxShadow: 'none',
      },
    },
  });

  const handleClose = () => {
    onClose();
    setPagination({ pageIndex: 0, pageSize: 10 });
    setReferences(undefined);
    setErrorOnly(false);
  };

  const handleDownloadErrorReport = async () => {
    await downloadImportErrorReport(importReportId);
  };

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <div className="UIImportDetailsModal">
          <div className="UIImportDetailsModal__Container">
            {importReport?.parameters?.publication_id && (
              <>
                <FormLabel label="PXM ID" />
                <div>{importReport.parameters.publication_id}</div>
              </>
            )}
            <FormLabel label="Steps" />
            <div className="UIImportDetailsModal__Table__Steps">
              {(importReport.steps || []).flatMap((step, index) => [
                <Chip
                  key={step.name}
                  label={step.name}
                  color={step.status === 'SUCCESS' ? IColor.TEAL : IColor.RED}
                />,
                index < importReport.steps.length - 1 ? (
                  <span key={index}>&rarr;</span>
                ) : null,
              ])}
            </div>
            <FormLabel label="Items" />
            <div className="UIImportDetailsModal__Grid">
              <Switch
                label={'Errors only'}
                onChange={setErrorOnly}
                value={errorOnly}
              />
              <TextInput
                onChange={setReferences}
                value={references}
                placeholder="GTINs or product identifiers, separated by commas"
              />
              <Button
                onClick={handleDownloadErrorReport}
                label={'Download Error Report'}
                fullWidth
              />
            </div>
            <MaterialReactTable table={table} />
          </div>
        </div>
      </Modal>
      {step && (
        <ImportStepModal
          open={openStepModal}
          onClose={() => setOpenStepModal(false)}
          step={step}
        />
      )}
    </>
  );
};
