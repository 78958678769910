import React, { useRef, useState } from 'react';

import './FileInput.scss';

interface FileInputProps {
  onChange: (file: File | null) => void;
}

export const FileInput = ({ onChange }: FileInputProps) => {
  const [fileName, setFileName] = useState<string>('');
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const file = event.target.files?.[0];
    if (file) {
      setFileName(file ? file.name : '');
      onChange(file);
    }
  };

  const handleButtonClick = (): void => {
    fileInputRef.current?.click();
  };

  const handleClearSelection = () => {
    setFileName('');
    onChange(null);
  };

  return (
    <div className="UIFileInput">
      <input
        type="file"
        ref={fileInputRef}
        className="UIFileInput__Input"
        onChange={handleFileChange}
      />
      <button onClick={handleButtonClick} className="UIFileInput__Button">
        Select File
      </button>
      <span className="UIFileInput__Text">{fileName}</span>
      {fileName && (
        <span className="UIFileInput__Clear" onClick={handleClearSelection}>
          &times;
        </span>
      )}
    </div>
  );
};
