import { MutateOptions, useMutation } from '@tanstack/react-query';

import coreApi from 'resources/coreBaseApi';

import { IImportSupportStatus } from '../types';

const updateImportSupportStatus = async (
  id: number,
  status: IImportSupportStatus,
) => {
  const payload = { new_status: status };
  return await coreApi.post(`/etl/v1/import/support/status/${id}`, payload);
};

export const useUpdateImportSupportStatus = (
  id: number,
  status: IImportSupportStatus,
  options?: MutateOptions,
) => {
  return useMutation({
    mutationFn: () => updateImportSupportStatus(id, status),
    retry: 0,
    ...options,
  });
};
