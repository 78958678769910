import { MutateOptions, useMutation } from '@tanstack/react-query';

import coreApi from 'resources/coreBaseApi';

import { IImportReportType } from '../constants';

const createImport = async (
  type: IImportReportType,
  data: any = {},
  file: any,
) => {
  if (type === IImportReportType.DATA) {
    return await coreApi.post('/etl/v2/import/product/web', data, file);
  } else if (type === IImportReportType.RANGE) {
    return await coreApi.post('/etl/v1/import/productinshop', data, file);
  } else {
    return await coreApi.post('/gdsn/v2/as2/push', data, file);
  }
};

export const useCreateImport = (
  type: IImportReportType,
  data: any = {},
  file: any,
  options?: MutateOptions,
) => {
  return useMutation({
    mutationFn: () => createImport(type, data, file),
    retry: 0,
    ...options,
  });
};
