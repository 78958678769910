import { Modal } from '@mui/material';
import { IImportReportStep } from 'dashboards/import/types';
import { Chip, FormLabel } from 'dashboards/shared/components';
import { IColor } from 'dashboards/shared/types';

import './ImportStepModal.scss';

interface ImportStepModal {
  open: boolean;
  onClose: () => void;
  step: IImportReportStep;
}

export const ImportStepModal = ({ open, onClose, step }: ImportStepModal) => {
  return (
    <Modal open={open} onClose={onClose}>
      <div className="UIImportStepModal">
        <div className="UIImportStepModal__Section">
          <FormLabel label="Step" />
          <Chip
            label={step.name}
            color={step.status === 'SUCCESS' ? IColor.TEAL : IColor.RED}
          />
        </div>
        <div className="UIImportStepModal__Section">
          <FormLabel label="Time" />
          <span>{step.time}</span>
        </div>
        {step.message && (
          <div className="UIImportStepModal__Section">
            <FormLabel label="Message" />
            <span>{step.message}</span>
          </div>
        )}
        {step.validation_report && (
          <div className="UIImportStepModal__Section">
            <FormLabel label="Code" />
            <textarea
              defaultValue={JSON.stringify(step.validation_report, null, 2)}
              className="UIImportStepModal__Code"
              rows={10}
            />
          </div>
        )}
      </div>
    </Modal>
  );
};
