import { useQuery } from '@tanstack/react-query';
import qs from 'querystringify';

import coreApi from 'resources/coreBaseApi';

import { IImportFilters, IImportReport } from '../types';

interface IAPIImportReports {
  data: {
    data: IImportReport[];
    totalResults: number;
  };
}

const TEST_USER_IDS = [79];

const fetchImportReports = async (
  filters: IImportFilters,
): Promise<IAPIImportReports> => {
  const params = { ...filters };
  if (filters.filter_user_ids_out) {
    params.filter_user_ids_out = TEST_USER_IDS as unknown as boolean;
  } else {
    params.filter_user_ids_out = undefined as unknown as boolean;
  }
  const realParams = Object.fromEntries(
    Object.entries(params).flatMap(([key, value]) =>
      value !== undefined ? [[key, value]] : [],
    ),
  );
  return await coreApi.get(
    '/etl/v1/products/import/report' + qs.stringify(realParams, true),
  );
};

export const useGetImportReports = (filters: IImportFilters) => {
  return useQuery<IAPIImportReports, Error>({
    queryKey: ['importReports', filters],
    queryFn: () => fetchImportReports(filters),
    retry: 0,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
};
