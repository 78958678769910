import './DateInput.scss';

interface DateInputProps {
  value: string | undefined;
  onChange: (value: string | undefined) => void;
  placeholder?: string;
  disabled?: boolean;
}

export const DateInput = ({ value, onChange, disabled }: DateInputProps) => {
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  return (
    <div className="UIDateInput">
      <input
        type="date"
        className="UIDateInput__Input"
        value={value || ''}
        onChange={handleInputChange}
        disabled={disabled}
      />
    </div>
  );
};
