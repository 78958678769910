import { Modal } from '@mui/material';
import { useReplayImport } from 'dashboards/import/hooks';
import { IImportReport } from 'dashboards/import/types';
import { Button, FormLabel } from 'dashboards/shared/components';
import { useEffect, useState } from 'react';

import { isValidJSON } from 'utils';
import { getIn } from 'utils/immutable';

import './ImportReplayModal.scss';

interface ImportReplayModalProps {
  open: boolean;
  onClose: () => void;
  importReport?: IImportReport;
  refetch: () => void;
}

export const ImportReplayModal = ({
  open,
  onClose,
  importReport,
  refetch,
}: ImportReplayModalProps) => {
  const [parameters, setParameters] = useState('');
  const [disabled, setDisabled] = useState(false);

  const getInitialJson = () => {
    if (importReport) {
      const parameters: any = Object.assign(
        {},
        importReport.parameters,
        importReport.replayParameters,
        {
          replay_info: {
            original_import_id:
              getIn(
                importReport,
                'parameters.replay_info.original_import_id',
              ) || importReport.id,
          },
        },
      );
      if (!parameters.organization_id) {
        if (importReport.targetOrganizations.length === 1) {
          parameters.organization_id = importReport.targetOrganizations[0].id;
        } else if (
          importReport.sourceOrganization &&
          importReport.sourceOrganization.id &&
          importReport.sourceOrganization.id !== 1
        ) {
          parameters.organization_id = importReport.sourceOrganization.id;
        }
      }

      return JSON.stringify(parameters, null, 2);
    }
    return '';
  };

  useEffect(() => {
    setParameters(getInitialJson());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [importReport]);

  const handleChange = (event) => {
    const value = event.target.value;
    if (isValidJSON(value)) {
      setParameters(value);
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  const { mutate: replay } = useReplayImport(parameters, {
    onSuccess: () => {
      onClose();
      refetch();
    },
  });

  return (
    <>
      <Modal open={open} onClose={onClose}>
        <div className="UIImportReplayModal">
          <div className="UIImportReplayModal__Section">
            <FormLabel label="Replay" />
            <textarea
              defaultValue={parameters}
              className="UIImportReplayModal__Code"
              onChange={handleChange}
              rows={20}
            />
          </div>
          <div className="UIImportReplayModal__Section">
            <Button
              onClick={replay}
              label={'Replay'}
              fullWidth
              disabled={disabled}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};
