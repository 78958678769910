import PropTypes from 'prop-types';

import { SwitchButton } from '@alkem/react-ui-button';

const OrganizationActivated = (props) => (
  <li
    className="list-group-item"
    style={{ display: 'flex', alignItems: 'center' }}
  >
    <SwitchButton
      id="tender-modal-form-line-mdd"
      checked={props.organization.active}
      onChange={props.onToggle}
      disabled
    />
    <span className="list-group-label" data-testid="organizationActivated-info">
      {props.organization.nameLegal} - {props.organizationId}{' '}
      {props.showNetwork && `[${props.organization.network.name}]`}
    </span>
    {!props.readOnly && (
      <span
        className="list-group-delete mdi mdi-delete org-list-group-delete"
        data-testid={`org-list-group-delete-${props.organizationId}`}
        onClick={props.onDelete}
      />
    )}
  </li>
);

OrganizationActivated.propTypes = {
  organizationId: PropTypes.string.isRequired,
  organization: PropTypes.object.isRequired,
  onToggle: PropTypes.func.isRequiredq,
  onDelete: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  showNetwork: PropTypes.bool,
};

OrganizationActivated.defaultProps = {
  showNetwork: false,
  readOnly: false,
};

export default OrganizationActivated;
