import './TextInput.scss';

interface TextInputProps {
  value: string | undefined;
  onChange: (value: string | undefined) => void;
  placeholder?: string;
  disabled?: boolean;
}

export const TextInput = ({
  value,
  onChange,
  placeholder = 'Type something...',
  disabled,
}: TextInputProps) => {
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  return (
    <div className="UITextInput">
      <input
        type="text"
        className="UITextInput__Input"
        placeholder={placeholder}
        value={value || ''}
        onChange={handleInputChange}
        disabled={disabled}
      />
    </div>
  );
};
