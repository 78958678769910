import { Modal } from '@mui/material';
import { useUpdateImportStatus } from 'dashboards/import/hooks';
import { IImportReport, IImportStatus } from 'dashboards/import/types';
import { Button, FormLabel, SingleSelect } from 'dashboards/shared/components';
import { useEffect, useState } from 'react';

import './ImportStatusModal.scss';

interface ImportStatusModalProps {
  open: boolean;
  onClose: () => void;
  importReport?: IImportReport;
  refetch: () => void;
}

export const ImportStatusModal = ({
  open,
  onClose,
  importReport,
  refetch,
}: ImportStatusModalProps) => {
  const [status, setStatus] = useState<string | undefined>(
    importReport?.status,
  );

  const isValidForm = () => {
    return importReport?.status !== status;
  };

  const { mutate: updateStatus } = useUpdateImportStatus(
    importReport?.id as number,
    status as IImportStatus,
    {
      onSuccess: () => {
        onClose();
        refetch();
      },
    },
  );

  const statuses = Object.keys(IImportStatus).map((key) => ({
    id: key,
    label: key,
  }));

  useEffect(() => {
    setStatus(importReport?.status);
  }, [importReport]);

  return (
    <Modal open={open} onClose={onClose}>
      <div className="UIImportStatusModal">
        <div className="UIImportStatusModal__Section">
          <FormLabel label="Status" />
          <SingleSelect
            options={statuses}
            onChange={setStatus}
            value={status}
          />
        </div>
        <div className="UIImportStatusModal__Section">
          <Button
            onClick={updateStatus}
            label={'Update'}
            fullWidth
            disabled={!isValidForm()}
          />
        </div>
      </div>
    </Modal>
  );
};
