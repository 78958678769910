export const REDUCER_KEY = 'REFERENTIALS';

export const ADD_ENTITY = 'ADD_ENTITY';
export const DELETE_ENTITY = 'DELETE_ENTITY';
export const RECEIVED_REFERENTIAL_ENTITIES = 'RECEIVED_REFERENTIAL_ENTITIES';
export const RECEIVED_REFERENTIAL_LIST = 'RECEIVED_REFERENTIAL_LIST';
export const RECEIVED_REFERENTIAL_GROUPS = 'RECEIVED_REFERENTIAL_GROUPS';
export const RECEIVED_REFERENTIAL_LINKEDATTRIBUTES =
  'RECEIVED_REFERENTIAL_LINKEDATTRIBUTES';
export const SELECT_REFERENTIAL = 'SELECT_REFERENTIAL';
export const UPDATE_ENTITY_KEY = 'UPDATE_ENTITY_KEY';
export const REFRESHING_REFERENTIAL_CACHE = 'REFRESHING_REFERENTIAL_CACHE';
export const SELECT_REFERENTIAL_LOADING = 'SELECT_REFERENTIAL_LOADING';
export const REFERENTIAL_LINKEDATTRIBUTES_LOADING =
  'REFERENTIAL_LINKEDATTRIBUTES_LOADING';
export const SELECT_REFERENTIAL_GROUP = 'SELECT_REFERENTIAL_GROUP';
